<template>
  <div class="rule">
    <div class="tooltip">所下载文件会默认保存在电脑中的【下载】文件夹，或者网页浏览器下载工具中设置的【默认保存】文件夹</div>
    <div class="total">
      <div class="left">
        <div class="bread" v-for="(item, index) in breadCrumbList" :key="index">
          <span class="title" :class="index + 1 !== breadCrumbList.length ? 'green' : ''"
                @click="breadInfo(item, index)"> {{ item.name }}</span>
          <span class="line" v-if="index + 1 !== breadCrumbList.length">/</span>
        </div>
      </div>
      <div class="right">
        <el-button type="text" class="total-right vertical-center" @click="allDown">
          <img src="~assets/images/index/down_blue.png" alt="" style="vertical-align: bottom" class="del"/>
          <img src="~assets/images/index/down_hover.png" alt="" style="vertical-align: bottom" class="del-hover"/>
          <span style="margin-left: 8px">批量下载</span>
        </el-button>
      </div>
    </div>

    <el-table
        v-loading="loading"
        class="table"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        :data="listData"
        tooltip-effect="dark"
        :header-cell-style="{ background: '#fff', color: '#1C1F21', fontWeight: '400' }"
        border
    >
      <el-table-column type="selection" label="全选" width="90" align="center"></el-table-column>
      <el-table-column label="名称" class="name" :width="getWidth()">
        <template slot-scope="scope">
          <div class="el-name file_name" @click="videoPlay(scope.row)">
            <img src="~assets/images/index/files.png" alt="" v-if="scope.row.is_catalogue === 1"/>
            <img src="~assets/images/index/play_video.png" alt="" v-if="scope.row.file_model_type === 'video'"/>
            <el-image v-if="scope.row.file_model_type === 'image'" :src="scope.row.path"
                      :preview-src-list="srcList(scope.row)"></el-image>
            <img src="~assets/images/index/word.png" alt="" v-if="scope.row.file_model_type === 'info'"/>
            <p @click="breadCrumb(scope.row)">{{ scope.row.name }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="大小" width="150">
        <template slot-scope="scope">
          <div class="el-name">
            {{
              scope.row ? ((scope.row.size / 1024 / 1024).toFixed(2) > 1000 ? (scope.row.size / 1024 / 1024 / 1024).toFixed(2) + 'GB' : (scope.row.size / 1024 / 1024).toFixed(2) + 'MB') : ''
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建人" width="150px" v-if="is_admin === '1'">
        <template slot-scope="scope">
          <div class="el-name el-nickname">{{ scope.row.user ? scope.row.user.nickname : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="200">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.created_at }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="110">
        <template slot-scope="scope">
          <el-button type="text" size="small" class="table-edit" @click="down(scope.row)"> 下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="page"
        background
        v-if="listData.length !== 0 && total > 10"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        layout="prev, pager, next,total, jumper"
        :total="total"
    >
    </el-pagination>
    <!--视频弹框-->
    <el-dialog
        class="dzdialog"
        :class="minDio ? 'mindialog' : ''"
        :title="videoTitle"
        :visible.sync="isvideoDialogVisible"
        top="20vh"
        width="840px"
        v-if="isvideoDialogVisible"
        :before-close="handleClose"
    >
      <play-video :file_id="media_id" class="play-video"></play-video>
    </el-dialog>
    <!-- app弹框-->
    <el-dialog class="appdialog" title="提示" :destroy-on-close="true" :visible.sync="isappDialogVisible" top="20vh"
               width="554px" v-if="isappDialogVisible" :before-close="handleAppClose">
      <p>该分享内容请点击“链接地址” <span class="code" @click="copyCodeUrl">链接地址</span> 复制在客户端中下载，是否下载“君莲书院”客户端？
      </p>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="handleAppClose">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="downApp">立即下载</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PlayVideo from 'components/playVideo';

export default {
  props: {},
  data() {
    return {
      versionInfo: '',
      isappDialogVisible: false,
      loading: false,
      page: 1,
      pageSize: 10,
      total: 0,
      id: '',
      parent_id: '',
      type: '',
      name: '',
      listData: [],
      breadCrumbList: [],
      user_id: '',
      circle_id: '',
      isWeb: true,
      expiration_time_key: '',
      videoTitle: '',
      media_id: '',
      isvideoDialogVisible: '',
      is_admin: '',
      minDio: false,
      multipleSelection: [],
      code: '',
      codeUrl: '',
      host: 'www.helixlife.com.cn',
      protocol: '',
    };
  },
  created() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
    }
    this.protocol = window.location.protocol;
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.user_id = this.$route.query.user_id;
    this.circle_id = this.$route.query.circle_id;
    this.circle_id = this.$route.query.circle_id;
    this.is_admin = this.$route.query.is_admin;
    this.expiration_time_key = this.$route.query.expiration_time_key;
    console.log(this.host);
  },
  watch: {
    id() {
      this.breadCrumbList = [
        {
          id: this.id,
          name: '全部文件',
        },
      ];
    },
  },
  mounted() {
    this.getList();
    document.body.style.backgroundColor = '#fff';
  },
  destroyed() {
    document.body.style.backgroundColor = '#b7bbbf99';
  },
  methods: {
    copyCodeUrl() {
      let that = this;
      const save = function (e) {
        e.clipboardData.setData('text/plain', that.codeUrl);
        e.preventDefault(); // 阻止默认行为
      };
      document.addEventListener('copy', save); // 添加一个copy事件
      document.execCommand('copy'); // 执行copy方法
      this.$message({message: '复制成功', type: 'success'});
    },
    downApp() {
      window.location.href = this.versionInfo;
      setTimeout(() => {
        this.handleAppClose();
      }, 300);
    },
    handleAppClose() {
      this.isappDialogVisible = false;
    },
    // selectEnable(row) {
    //   if (row.is_catalogue === 1) {
    //     return false;
    //   } else {
    //     return true; // 不禁用
    //   }
    // },
    // 批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取app下载地址
    getAppUrl() {
      let versionInfoUrl = this.$api.versionInfo;
      this.$http.get(versionInfoUrl).then((res) => {
        this.versionInfo = res.data.data.apk_url;
      });
    },
    // 批量下载
    allDown() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请先选择文件',
          type: 'warning',
        });
      } else {
        let videoList = [];
        let imageList = [];
        let infoList = [];
        let catalogueList = [];
        let catalogue_ids = [];
        let video_ids = [];
        let image_ids = [];
        let info_ids = [];
        let downUrl = this.$api.batchCatalogues;
        let down_key = new Date().getTime();
        // 多个文件
        catalogueList = this.multipleSelection.filter((e) => {
          return e.is_catalogue === 1;
        });
        videoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'video';
        });
        imageList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'image';
        });
        infoList = this.multipleSelection.filter((e) => {
          return e.file_model_type === 'info';
        });
        if (catalogueList.length > 0) {
          this.isappDialogVisible = true;
          this.getAppUrl();
          return;
        }
        videoList.forEach((e) => {
          video_ids.push(e.id);
        });
        imageList.forEach((e) => {
          image_ids.push(e.id);
        });
        infoList.forEach((e) => {
          info_ids.push(e.id);
        });

        let numData = {
          catalogue_ids: catalogue_ids.length > 0 ? catalogue_ids : '',
          video_ids: video_ids.length > 0 ? video_ids : '',
          image_ids: image_ids.length > 0 ? image_ids : '',
          info_ids: info_ids.length > 0 ? info_ids : '',
          is_recycle: '0',
          action: 'download',
          down_key,
        };

        this.$http.post(downUrl, numData).then((res) => {
          console.log(res.data);
          if (res.data.success) {
            let title = '';
            let url = '';
            this.multipleSelection.forEach((e) => {
              console.log(e);
              if (e.file_model_type === 'video') {
                title = `${e.name}.${e.content.MediaUrl.split('.').pop()}`;
                url = e.content.MediaUrl;
              } else {
                title = `${e.name}.${e.path.split('.').pop()}`;
                url = e.path;
              }
              this.$util.downloadRes(url, title);
            });
            this.getCataloguesList();
            this.$message({
              message: '下载中.......,请去下载内容中查看下载进度',
              type: 'success',
            });
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      }
      // }
    },
    handleClose() {
      this.isvideoDialogVisible = false;
    },

    // 图片预览
    srcList(row) {
      let arr = [];
      arr.push(row.path);
      return arr;
    },
    //视频弹框
    videoPlay(val) {
      console.log(val);
      if (val.file_model_type === 'video') {
        this.videoTitle = val.file_name;
        this.media_id = val.content.MediaUrl;
        this.isvideoDialogVisible = true;
      }
    },
    getWidth() {
      if (window.screen.width < 500) {
        this.minDio = true;
        return 400;
      }
    },

    down(row) {
      let parmas = {};
      let numUrl = `${this.$api.catalogues}/${row.id}/${this.$api.download}`;
      let down_key = new Date().getTime();
      if (row.is_catalogue === 1) {
        this.codeUrl = `${this.protocol}//${this.host}/sharefiles?code=${this.code}&name=${this.name}`;
        this.isappDialogVisible = true;
        this.getAppUrl();
        return;
      }
      if (this.is_admin === '1') {
        parmas = {
          type: row.file_model_type,
          circle_id: this.circle_id,
          down_key,
        };
      } else {
        parmas = {
          type: row.file_model_type,
          circle_id: this.circle_id,
          user_id: this.user_id,
          down_key,
        };
      }

      this.$http.post(numUrl, parmas, true).then((res) => {
        if (res.data.success) {
          if (row.file_model_type === 'video') {
            this.$util.downloadRes(row.content.MediaUrl, `${row.name}.${row.content.MediaUrl.split('.').pop()}`);
          } else {
            this.$util.downloadRes(row.path, `${row.name}.${row.path.split('.').pop()}`);
          }
          this.$message({
            message: '下载中.......,请去下载内容中查看下载进度',
            type: 'success',
          });
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning',
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    getList() {
      let url = '';

      if (this.is_admin === '1') {
        url = `${this.$api.catalogues}/${this.id}?type=${this.type}&circle_id=${this.circle_id}&expiration_time_key=${this.expiration_time_key}`;
      } else {
        url = `${this.$api.catalogues}/${this.id}?type=${this.type}&user_id=${this.user_id}&circle_id=${this.circle_id}&expiration_time_key=${this.expiration_time_key}`;
      }

      this.$http.get(url).then((res) => {
        if (res.data.success) {
          this.listData = res.data.data;
          let name = '';
          name = this.$handle.urlEncode(this.listData[0].name);
          this.name = name;
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning',
          });
        }
      });
    },

    // 获取列表
    getCataloguesList() {
      this.loading = true;
      let url = '';
      if (this.is_admin === '1') {
        url = `${this.$api.catalogues}?parent_id=${this.parent_id}&circle_id=${this.circle_id}`;
      } else {
        url = `${this.$api.catalogues}?parent_id=${this.parent_id}&user_id=${this.user_id}&circle_id=${this.circle_id}`;
      }

      this.$http.get(url).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.listData = res.data.data.data;
        }
      });
    },
    breadInfo(item, index) {
      this.breadCrumbList.splice(index + 1);
      this.parent_id = item.id;
      if (index == 0) {
        this.getList();
      } else {
        this.getCataloguesList();
      }
    },
    breadCrumb(row) {
      if (row.is_catalogue === 1) {
        this.parent_id = row.id;
        this.type = 'catalogue';
        this.getCataloguesList();
        this.breadCrumbList.push(row);
      }
    },
  },

  components: {PlayVideo},
};
</script>
<style lang="scss" scoped>
.rule {
  padding: 40px 20px;

  .tooltip {
    margin-bottom: 30px;
  }

  .total {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-left: 13px;
    box-sizing: border-box;

    .left {
      display: flex;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #93999f;

      .bread {
        display: flex;
        align-items: center;

        .line {
          display: inline-block;
          margin: 0 4px;
        }

        span {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .title {
        cursor: pointer;
      }

      .green {
        color: #81d8d0;
      }
    }

    .right {
      display: flex;
    }
  }

  .total-right {
    color: #008aff;
    margin-left: 30px;
    padding: 0;
    margin-top: 8px;

    .del-hover {
      display: none;
    }
  }

  .total-right:hover {
    color: #005aa7;

    .del {
      display: none;
    }

    .del-hover {
      display: inline-block;
    }
  }

  .title {
    cursor: pointer;
  }

  .green {
    color: #81d8d0;
  }
}

:deep(.el-table) {
  margin-top: 20px;

  .user_info {
    display: flex;
    align-items: center;

    .img {
      width: 60px;
      height: 60px;
      border-radius: 6px;

      img {
        width: 60px;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
    }

    .img_info {
      padding: 0 14px;
      margin-right: 14px;
    }

    .imgname {
      width: 245px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      // font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1f21;
    }

    .size {
      font-size: 12px;
      // font-family: Microsoft YaHei;
      font-weight: 400;
      color: #93999f;
    }

    .state_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58px;
      height: 18px;
      background: #81d8d0;
      border-radius: 9px;
      font-size: 12px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    .release_btn {
      background: #008aff;
    }

    .close_btn {
      background: #71777d;
    }
  }

  // .selection {
  //   display: none;
  // }
  .el-name {
    width: 100%;

    color: #71777d;
  }

  .el-nickname {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-image {
    width: 32px;
    height: 32px;
    margin-right: 20px;
    border-radius: 6px;
  }

  .file_name {
    display: flex;
    align-items: center;

    img {
      margin-right: 14px;
    }

    p {
      width: 256px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p:hover {
      cursor: pointer;
    }
  }

  .table-edit {
    font-size: 14px;
    color: #008aff;
  }

  .table-span {
    cursor: pointer;
  }
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  :deep(.el-input) {
    width: 36px;
    height: 30px;
  }
}

:deep(.el-pagination.is-background .btn-prev),
:deep(el-pagination.is-background .btn-next),
:deep(.el-pagination.is-background .el-pager li) {
  margin: 0 5px;
  background-color: #ffffff;
  color: #93999f;
  line-height: 30px;
  width: 32px;
  height: 32px;
  border: 1px solid #d0d6de;
  border-radius: 2px;

  .active {
  }
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
  background-color: #81d8d0 !important;
  color: #fff !important;
  border: 0;
}

.dzdialog {
  :deep(.el-dialog) {
    border-radius: 10px;
  }

  :deep(.el-dialog__header) {
    padding: 15px 0 16px 20px;
    border-bottom: 1px solid #ebeff2;

    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
      color: #1c1f21;
    }
  }

  :deep(.el-dialog__body) {
    padding: 20px 30px 30px 30px;
  }

  :deep(.el-dialog__footer) {
    padding: 0 20px 40px;
  }
}

.mindialog {
  :deep(.el-dialog) {
    width: 95vw !important;
  }

  .play-video {
    .vcp-player {
      width: 320px !important;
    }
  }
}

.circles-wrap {
  display: none !important;
}

.appdialog {
  :deep(.el-dialog) {
    border-radius: 10px;
  }

  p {
    .code {
      cursor: pointer;
      color: #81d8d0;
    }
  }
}
</style>
